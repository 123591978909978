<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Edit Group</h1>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                        <div v-if="!isLoad" class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                            <form @submit.prevent="patchGroup()" class="row p-2">
                                <div class="col-lg-6 form-group mb-3">
                                    <label class="mont-font fw-600 font-xsss" for="">Term</label>
                                    <select disabled v-model="form.cycle" required class="form-control text-grey-900 font-xsss fw-600">
                                        <option value="" selected disabled>Choose Term</option>
                                        <option value="1">Term 1</option>
                                        <option value="2">Term 2</option>
                                        <option value="3">Term 3</option>
                                    </select>
                                </div>
                                <div class="col-lg-6 form-group mb-3">
                                    <label class="mont-font fw-600 font-xsss" for="">Group Name</label>
                                    <input v-model="form.name" type="text" placeholder="Insert group name" required class="form-control text-grey-900 font-xsss fw-600">                        
                                </div>
                                <div class="col-xl-12 col-lg-12 form-group text-right my-4">
                                    <router-link :to="{name: 'PeopleCoursesAdmin', params: {idCourse: paramsId}, hash: '#group'}" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                    <input type="Submit" class="btn btn-warning text-white" value="Submit">
                                </div>
                            </form>
                        </div>
                        <div v-else class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                            <div class="row">
                                <div class="col-12 text-center py-3">
                                    <div class="my-3">
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "RubricsCoursesCreate",
    data(){
        return {
            paramsId: this.$route.params.idCourse,
            idGroup: this.$route.params.idGroup,
            isLoad: true,
            form: {
                id: '',
                cycle: '',
                name: '',
            },
        }
    },
    created() {
        this.getGroup()
    },
    methods:{
        async getGroup(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/people/group/detail?slug=${this.paramsId}&id=${this.idGroup}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.form = res.data.data
                this.isLoad = false
            })
        },
        async patchGroup() {
            var data = {
                _method: 'patch',
                slug : this.paramsId,
                id : this.form.id,
                cycle : this.form.cycle,
                name : this.form.name,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/people/group`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Group!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'PeopleCoursesAdmin', params: {idCourse: this.paramsId}, hash: '#group' })
            }).catch((err) => {
                if (err.response.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Group !',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    }
}
</script>
